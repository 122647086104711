import styled from "styled-components";
import React from "react";
import * as constants from "../../style/constants";
const StyledForm = styled.form`
    display:flex;
    font-family: ${constants.Cfont}, ${constants.Dfont};
  @media (max-width: 375px) {
      align-self: center;
      width: 300px;
      height: 500px;
      flex-direction: column;
      align-items:center;
      justify-content:space-around;
      font-weight:600;
  }
  @media (min-width: 414px) and (max-width: 767px) {
    margin-top: 30px;
    align-self: center;
    flex-direction: column;
    width: 400px;
    height: 500px;
    justify-content:space-around;
    font-weight:600;
    align-items:center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width:700px;
    height: 500px;
    align-self: center;
    align-items:center;
    justify-content: space-around;
    flex-wrap:wrap;
    display: flex;
    flex-direction:column;
      font-weight:600;
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    margin-top:80px;
    flex-direction:row;
    width:506px;
    height:460px;
    align-self: center;
    align-items:center;
    justify-content: space-around;
    flex-wrap:wrap;
    display: flex;
      font-weight:600;
  }
  @media (min-width: 1500px) {
    margin-top:80px;
    flex-direction:row;
    font-size: 1.3rem;
    width:800px;
    height: 650px;
    align-self: center;
    align-items:center;
    justify-content: space-around;
    flex-wrap:wrap;
    display: flex;
      font-weight:600;
`;

export default function Styledform({ children }) {
  return <StyledForm>{children}</StyledForm>;
}
