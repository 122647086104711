import styled from 'styled-components'
import React from 'react'
const TitleLabel = styled.label`
    margin-bottom: 4px;
`;

export default function titlelabel({children, className}) {
    return (
        <TitleLabel className={className}>{children}</TitleLabel>
    )
}
