import * as React from "react";
import StyledForm from "../components/contactus/StyledForm";
import TitleLabel from "../components/contactus/titlelabel";
import StyledContact from "../components/contactus/styledcontact";
import "normalize.css";
import UEp from "../components/paragragh";
import Footer from "../components/footer"
import "../style/Fonts.css";
import "../style/layouts.css";
import Layout from "../components/layout";
import TitleCard from "../components/title";
import Navbar from "../components/Navbar";
import ContentDiv from "../components/contactus/contentdiv";
import styled from "styled-components";
import * as constants from "../style/constants";
import { useMediaQuery } from "react-responsive";

const IndexPage = () => {
  const isSmallPhone = useMediaQuery({ query: "(max-width: 413px)" });
  const isPhone = useMediaQuery({
    query: "(min-width: 414px) and (max-width: 767px)"
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1499px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1500px)" });
  const showNav = useMediaQuery({query:"(min-width:1024px)"})
  return (
    <Layout responsive={isSmallPhone || isPhone || isTablet? "phone": ""}>
      <StyledMain>
        <title>Our Process</title>
        {showNav&&<Navbar />}
        {(isLaptop || isBigScreen) &&<div style={{ marginTop: "3rem" }}></div>}
        <TitleCard
          style={{
            color: constants.black,
            fontSize: (!isLaptop && !isBigScreen && "35px") || "",
            marginLeft: ((isTablet || isBigScreen) && "30px") || "",
          }}
        >联系我们</TitleCard>
        <ContentDiv>
          <StyledContact>
            <StyledP>
              美国办公室
              <br />
              Pearson court square
              <br />
              Long island city, NY, 11101
              <br />
              +1 (917)-770-7137
            </StyledP>
            <StyledP>
              加拿大办公室
              <br />
              503-889 Bay Street
              <br />
              Toronto, Ontario, M5S 3K5
              <br />
              +1 (289) 775-5199
              <br />
              Contact@nativue.com
            </StyledP>
          </StyledContact>
          <StyledForm className={"form-1"}>
            <StyledInput>
              <TitleLabel>姓名:</TitleLabel>
              <br />
              <InnerInput type="text" name="name" />
              名字
            </StyledInput>
            <StyledInput issingle={'true'}>
              <InnerInput type="text" name="name" />
              姓氏
            </StyledInput>
            <StyledInput >
              <TitleLabel>Email:</TitleLabel>
              <EmailInput type="text" name="name" />
            </StyledInput>
            <StyledInput isarea={'true'}>
              <TitleLabel>您需要翻译的语言是?:</TitleLabel>
              <StyledArea type="text" name="name" />
            </StyledInput>
            <StyledSubmit type="submit" value="提交" />
          </StyledForm>
        </ContentDiv>
      </StyledMain>
      <Footer />
    </Layout>
  );
};
const StyledMain = styled.main`
  @media screen and (max-width: 413px) {
    padding-top: 100px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 414px) and (max-width: 767px) {
    padding-top: 130px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 120px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
    min-height:1200px;
  }
  @media (min-width: 1500px) {
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
    min-height:1200px;
  }
`;
const StyledInput = styled((props)=> <div {...props}/>)`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) and (max-width: 1499px) {
    height:${props =>props.isarea? '150px': '106px'};
    justify-content:flex-end;
  }
  @media (min-width: 1500px) {
    height:${props =>props.isarea? '150px': '120px'};
    justify-content:flex-end;
  }
`;
const InnerInput = styled.input`
  background-color: #fafafa;
  border: 1px solid lightgrey;
  @media (max-width: 413px) {
    margin-bottom: 4px;
    width: 280px;
    height: 25px;
  }
  @media (min-width: 414px) and (max-width: 767px) {
    margin-bottom: 4px;
    width: 360px;
    height: 25px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-bottom: 4px;
    margin-right: 30px;
    width: 500px;
    height: 25px;
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    margin-bottom: 10px;
    width: 240px;
    height:30px;
  }
  @media (min-width: 1500px) {
    margin-bottom: 10px;
    width: 380px;
    height:30px;
  }
`;
const EmailInput = styled(InnerInput)`
  @media (min-width: 1024px) and (max-width: 1499px) {
    width: 500px;
  }
  @media (min-width: 1500px) {
    width: 780px;
  }
`;
const StyledArea = styled.textarea`
  background-color: #fafafa;
  border: 1px solid lightgrey;
  @media (max-width: 413px) {
    height: 80px;
    width: 280px;
  }
  @media (min-width: 414px) and (max-width: 767px) {
    height: 80px;
    width: 360px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-bottom: 4px;
    margin-right: 30px;
    width: 500px;
    height: 120px;
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    width: 500px;
    height: 180px;
  }
  @media (min-width: 1500px){
    width: 780px;
    height: 180px;
  }
`;
const StyledSubmit = styled.input`
  background-color: ${constants.white};
  width: 80px;
  height: 50px;
  border: 1px solid ${constants.offBlack};
  @media (min-width: 1500px){
    width: 120px;
    height: 90px;
    font-size: 30px;
  }
`;
const StyledP = styled(UEp)`
  @media (max-width: 413px) {
      font-size:1.2rem;
      margin-top:2rem;
  }
  @media (min-width: 414px) and (max-width: 767px) {
      font-size:1.3rem;
      margin-top:2rem;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
      font-size:1.3rem;
      line-height: 2.2rem;
      margin-top:2rem;
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
      font-size:1.3rem;
      text-align:center;
      width: 300px;
      line-height: 2.2rem;
      margin-top:2rem;
  }
  @media (min-width: 1440px) and (max-width: 1499px) {
      font-size:1.5rem;
      text-align:center;
      width: 300px;
      line-height: 2.2rem;
      margin-top:2rem;
  }
  @media (min-width: 1500px) {
      font-size:1.8rem;
      text-align:center;
      width: 400px;
      line-height: 2.2rem;
      margin-top:5rem;
`;
export default IndexPage;
