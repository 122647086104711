import styled from 'styled-components'
let ContentDiv;
export default ContentDiv = styled.div`
    display:flex;
  @media (max-width: 413px) {
      flex-direction: column;
  }
  @media (min-width: 414px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  @media (min-width: 1500px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

`;